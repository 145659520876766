/*
 * @Author: 智客云网络科技
 * @Date: 2021-04-19 11:07:53 +0800
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-01-22 00:06:19
 * @Description: 页面专属接口管理
 * @FilePath: \src\views\Frame\Console\api.js
 */

import http from "@/api";

//获取Api日志
export const getApiLog = (params) =>
  http.get("/v1/ApiLog/GetApiLogTable", params);

// 获取系统操作日志
export const getSystemLog = (params) =>
  http.get("/v1/Mlog/GetSystemLogTable", params);
